import styled from 'styled-components';

import { device } from '../../../utils';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Title = styled.div`
  display: flex;
  text-align: center;
  margin: 20px 0px;
  align-items: center;

  .red-mark {
    display: inline-block;
    width: 20px;
    height: 25px;
    background: #E1251B;
    transform: skew(-20deg);
    margin-right: 15px;

    @media ${device.tablet} {
      /* width: 25px;
      height: 30px; */
    }
  }


  @media ${device.tabletDown} {
    .bigtitle {
      font-size: 20px;
    }
  }

  @media ${device.tablet} {
    margin: 20px 20px;
    justify-content: center;
  }
`;

export const SEOBlock = styled.div`
  flex: 1;
  padding: 20px 30px;
  margin: 40px 0;
  background-color: #fafafa;

  p, a {
    font-size: 14px;
  }
`;