import React from 'react';
import ContentBlock from './ContentBlock';

import Menu from './Menu';
import HtmlRender from '../../../components/common/Html';

import { Wrapper, Title, SEOBlock } from './Range.styles';

const RangePage = (props) => {
  const { page, category, sections, categories } = props;
  // const CoverFluid = ShopifyFluid(coverImg, { maxWidth: 1600, maxHeight: 300 }, { aspectRatio: 4});
  return (
    <Wrapper>
      <div className="container">
        <Title>
          <div className="red-mark" />
          <h1 className="bigtitle">
            {page.title} {category?.title}
          </h1>
        </Title>
      </div>

      <Menu entries={categories} />
      <div className="content-blocs">
        {sections.map((section, idx) => (
          <ContentBlock
            key={idx}
            section={section}
            reverse={idx % 2}
            first={idx === 0}
          />
        ))}
      </div>
      <Menu entries={categories} />
      {!!category?.seo_block?.html && (
        <div className="container">
          <SEOBlock>
            <HtmlRender html={category?.seo_block?.html} />
          </SEOBlock>
        </div>
      )}
    </Wrapper>
  );
};

export default RangePage;
