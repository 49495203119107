import React from 'react';
import { graphql } from 'gatsby';

import Seo from '../../components/seo';
import RangeScreen from '../../screens/eshop/range';
import { oneMatch, Product } from '../../utils';
import usePreviewData from '../../utils/usePreviewData';
import { trackProductList } from '../../utils/gtm';
import { useLocale } from '../../services/i18n/useLocale';

const ProductRangeTemplate = ({ data: rawData, pageContext }) => {
  const data = usePreviewData(rawData);
  const intl = useLocale();

  const category = data?.category?.data;
  const page = data?.page.data;
  const products = (data?.products?.nodes || []).map((p) =>
    Product(p, intl.locale)
  );

  const sections = category.products
    .filter((item) => item.product.document)
    .map((item, idx) => {
      const infos = item.product.document.data;
      const current = products.find((p) =>
        oneMatch(
          p.skus,
          infos.shopify_product?.variants?.map((v) => v.sku)
        )
      );

      if (current) {
        current.trackList = 'Gamme';
        current.trackPosition = idx + 1;
      }
      return {
        ...infos,
        shopify_product: undefined,
        product: current,
      };
    });

  trackProductList(
    products
      .sort((a, b) => a.trackPosition - b.trackPosition)
      .map((p) => ({
        sku: p.defaultVariant?.sku,
        name: p.title,
        price: p.defaultVariant?.price,
        variant: p.defaultVariant?.title,
        category: p.productType,
        list: p.trackList,
        position: p.trackPosition,
      }))
  );

  const categories = page.categories
    .filter((i) => i.category.document)
    .map((i) => {
      const category = i.category.document;
      return {
        seoDescription: category.data.seo_description,
        title: category.data.title,
        url: category.data.link.url || `${data?.page?.url}/${category.uid}`,
      };
    });


  return (
    <>
      <Seo
        title={`${page.title} ${category?.title}`}
        description={category?.seo_description || undefined}
      />
      <RangeScreen
        page={{ ...page, categories: undefined }}
        category={category}
        categories={categories}
        sections={sections}
      />
    </>
  );
};

export const query = graphql`
  query (
    $prismic: ID!
    $locale: String!
    $pagePrismic: ID!
    # $cmsLocale: String!
    # $localeGlob: String!,
    $fetchProducts: [String!]
  ) {
    page: prismicProductrangeCategories(prismicId: { eq: $pagePrismic }) {
      url
      data {
        title
        categories {
          category {
            document {
              ... on PrismicProductrangeCategory {
                data {
                  title
                  link {
                    url
                  }
                }
                uid
              }
            }
          }
        }
      }
    }
    category: prismicProductrangeCategory(prismicId: { eq: $prismic }) {
      data {
        title
        seo_description
        seo_block {
          html
        }
        products {
          product {
            document {
              ... on PrismicProductrange {
                data {
                  title
                  product_packshot {
                    alt
                    dimensions {
                      height
                      width
                    }
                    url
                  }
                  link_name
                  link {
                    url
                    target
                  }
                  description
                  buy_link_name
                  buy_link {
                    url
                    target
                  }
                  viscosity {
                    html
                  }
                  cover {
                    alt
                    dimensions {
                      height
                      width
                    }
                    url
                  }
                  shopify_product {
                    variants {
                      sku
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    products: allShopifyProduct(
      filter: { skus: { in: $fetchProducts }, locale: { eq: $locale } }
    ) {
      nodes {
        shopifyAdminId
        ...ProductItem
        customfields {
          features {
            icon {
              cloudinary_src
              aspect_ratio
              alt
            }
            text
          }
        }
      }
    }
  }
`;
export default ProductRangeTemplate;
