import React from 'react';
import styled from 'styled-components';
import Image from 'gatsby-image';

import { device } from '../../utils';

const Wrapper = styled.div`
  display: grid;
  margin: 25px 0 10px;
  grid-gap: 20px;
  flex-wrap: wrap;

  .feature {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.3em;

    .gatsby-image-wrapper {
      flex: 0 0 auto;
    }

    span {
      margin-left: 10px;
    }
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`;

const Features = ({ product }) => {
  if (!product?.features?.length) {
    return null;
  }

  return (
    <Wrapper>
      {product.features.map((feature) => (
        <div
          key={feature.text}
          className="feature"
        >
          {feature.icon && (
            <Image
              fixed={feature.icon}
              alt={feature.text}
              loading="lazy"
              imgStyle={{
                objectFit: 'contain',
              }}
            />
          )}
          <span>{feature.text}</span>
        </div>
      ))}
    </Wrapper>
  );
};

export default Features;
