import styled from 'styled-components';
import MagicLink from '../../../components/link';
import { device } from '../../../utils';

export const Wrapper = styled.div`
  position: relative;
  margin: 60px 0px 30px;
  display: block;
  /* background-color: #fafafa; */
  /* margin: 30px 0; */

  &:first-child {
    margin-top: 0;
  }

  @media ${device.tablet} {
    margin: 50px 20px;
  }

  .simple-link.buy {
    color: #FFF;
  }

  .links {
    margin-top: 15px;

    > a {
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .packshot {
    width: 80%;
    max-height: 340px;
    margin: auto auto 0 auto;
  }
`;

export const Container = styled(MagicLink)`
  display: flex;
  flex-direction: column;

  .media, .text {
    flex: 1 1 50%;
    box-sizing: border-box;
  }

  .media {
    .image.gatsby-image-wrapper {
      background-color: #fafafa;
      height: 100%;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100px;
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
      }
      
      img {
        backface-visibility: hidden;
        transition: transform .3s ease-in-out, opacity .15s !important;
      }

      @media ${device.tablet} {
        &:after {
          display: none;
        }
      }
    }

    .image {
      &.hover {
        img {
          transform: scale(1.1);
        }
      }
    }
  }

  .content {
    /* flex: 1; */

    &.viscosity {
      p {
        margin-top: 5px;
        margin-bottom: 5px;
      }

      a.red {
        color: #111111;
        &:before {
          background-color: #111111;
        }
      }
    }
    p, a {
      @media ${device.laptopDown} {
        font-size: 14px;
      }
    }
  }

  .text {
    text-align: center;
    padding: 10px 20px;

    &.grey {
      padding-bottom: 0;
      background-color: #FAFAFA;
    }
  }

  @media ${device.tablet} {
    flex-direction: row;

    .media {
      margin-right: 10px;
      margin-bottom: 0;
      height: auto;
    }

    .text {
      padding: 20px;
      margin-left: 10px;
      display: flex;
      flex-direction: column;
    }

    .grey {
      transition: background-color .3s ease-in-out;

      &.hover {
        background-color: #E9E9E9;
      }
    }

    &.reverse {
      flex-direction: row-reverse;

      .media {
        margin-right: 0;
        margin-left: 10px;
      }

      .text {
        margin-left: 0%;
        margin-right: 10px;
      }
    }
  }

  @media ${device.laptopDown} {
    padding-left: 0;
    padding-right: 0;

    .features {
      display: none;
    }
  }
`;

export const Title = styled.h3`
  display: block;
  font-family: 'Montserrat';
  font-size: 23px;
  font-weight: bold;
  text-transform: uppercase;
  margin: -53px 0px 30px 0;
  color: #FFF;
  z-index: 100;
  position: relative;

  @media ${device.tablet} {
    margin: 0px 0px 10px 0;
    color: #000;
    font-size: 20px;
  }

  @media ${device.laptop} {
    font-size: 35px;
    margin: 0 0px 10px 0;
  }
`;

export const Subtitle = styled.div`
  font-size: 14px;
  max-width: 400px;
  margin: 0 auto 15px auto;

  @media ${device.tablet} {
    margin: 0 auto 30px auto;
  }
`;