import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { useLocation } from '@reach/router';

import { device } from '../../../utils';
import Link from '../../../components/link';

const Container = styled.div`
  @media ${device.tabletDown} {
    padding-left: 0;
    padding-right: 0;
  }
`;

const Wrapper = styled.ul`
  display: block;
  box-sizing: border-box;
  margin: 0px 0 30px 0;
  padding-left: 0;
  overflow: scroll hidden;
  white-space: nowrap;
  text-align: left;
  
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  li {
    position: relative;
    display: inline-block;
    margin: 0px 15px;
    list-style: none;
    padding: 7px 0;

    &:after {
      content: '';
      position: absolute;
      bottom: 0px;
      left: 0;
      right: 0;
      height: 2px;
      background: #000;
      transform: scaleX(0);
      transition: transform 300ms;
    }
    /* opacity: .35; */
    /* transition: opacity 300ms; */

    &:first-child {
      margin-left: 20px;
    }
    /* &:last-child {
      padding-right: 15px;
    } */

    a {
      display: block;
    }

    h2 {
      margin: 0;
    }

    a, h2 {
      font-family: 'Roboto';
      font-size: 16px;
      font-weight: 500;
      white-space: nowrap;
    }

    &.active {
      &:after {
        transform: scaleX(1);
      }
      /* opacity: 1; */
    }

    @media (hover: hover) {
      &:hover {
        &:after {
          transform: scaleX(1);
        }
        /* opacity: 1; */
      }
    }
  }

 
  
  @media ${device.tablet} {
    margin: 30px 0;

    li {
      margin: 0 15px;

      &:first-child {
        margin-left: 0;
      }

      a, h2 {
        font-size: 18px;
      }
    }
  }
`;

const cleanUrl = (path = '') => path.split('/').slice(2).join('/');

const Item = ({
  cat
}) => {
  const ref = useRef();
  const { pathname } = useLocation();
  const isActive = cleanUrl(pathname) === cleanUrl(cat.url);

  useEffect(() => {
    if (isActive) {
      ref.current.parentNode.scrollLeft = ref.current.offsetLeft - 20;
    }
  });

  return (
    <li
      className={cn({
        'active': isActive
      })}
      ref={ref}
    >
      <Link
        url={cat.url}
      >
        {isActive ? <h2>{cat.title}</h2> : <span>{cat.title}</span>}
      </Link>
    </li>
  );
};

const Menu = ({ entries }) => {
  return (
    <Container className="container">
      <Wrapper>
        {entries.map((cat, idx) => (
          <Item
            key={idx}
            cat={cat}
          />
        ))}
      </Wrapper>
    </Container>
  );
};

export default Menu;
