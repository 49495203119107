import React from 'react';
import cn from 'classnames';

import { nl2br, Imgix } from '../../../utils';
import HtmlRender from '../../../components/common/Html';
import Features from '../../../components/eshop/Features';
import MagicLink from '../../../components/link';

import { Wrapper, Title, Container, Subtitle } from './ContentBlock.styles';

const mediaResponsive = {
  xxs: 12,
  md: 6,
  contained: true,
};

const packshotResponsive = {
  xxs: 9,
  md: 6,
  contained: true,
};

const ContentBlock = ({ section, reverse = false, first = false }) => {
  const [hover, setHover] = React.useState(false);
  const setIsHover = React.useCallback(() => setHover(true), []);
  const setNotHover = React.useCallback(() => setHover(false), []);

  return (
    <Wrapper onMouseEnter={setIsHover} onMouseLeave={setNotHover}>
      <Container
        className={cn('container', {
          reverse,
        })}
        url={section.link?.url}
      >
        <div className="media">
          <Imgix
            {...section.cover}
            className={cn({
              hover,
            })}
            sizes={mediaResponsive}
            loading={first ? 'eager' : 'lazy'}
            fadeIn={!first}
            preloadHead={first}
          />
        </div>
        <div className={cn('text grey')}>
          <Title>{nl2br(section.title)}</Title>
          <Subtitle>{nl2br(section.description)}</Subtitle>
          <Imgix
            {...section.product_packshot}
            // sizes={imgResponsive}
            className="packshot"
            fadeIn={!first}
            loading={first ? 'eager' : 'lazy'}
            imgStyle={{
              objectFit: 'contain',
              objectPosition: 'bottom center',
            }}
            sizes={packshotResponsive}
          />
        </div>
      </Container>
      <Container
        className={cn('container', {
          reverse,
        })}
        as="div"
      >
        <div className="media features">
          <Features product={section.product} />
        </div>
        <div className="text">
          <HtmlRender
            html={section.viscosity?.html}
            className="content viscosity"
          />
          <div className="links">
            {!!section.link?.url && (
              <MagicLink
                url={section.link?.url}
                className="simple-link red more"
              >
                {section.link_name}
              </MagicLink>
            )}
            {!!section.buy_link?.url && (
              <MagicLink
                url={section.buy_link?.url}
                className="simple-link block buy"
              >
                {section.buy_link_name}
              </MagicLink>
            )}
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

export default ContentBlock;
